import React, { useState, useEffect, useRef } from 'react'

const FadeInWhenVisible = ({ children, delay = 200, duration = '1.2s', triggerOnce = true, direction = 'none', distance = 200 }) => {
  const [isVisible, setIsVisible] = useState(false)
  const domRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, delay);
          if (triggerOnce) {
            observer.unobserve(domRef.current);
          }
        } else if (triggerOnce) {
          setIsVisible(false);
        }
      });
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, [delay, duration, triggerOnce, direction]);

  const getTransform = () => {
    switch (direction) {
      case 'up':
        return isVisible ? 'translateY(0)' : `translateY(${distance}px)`
      case 'down':
        return isVisible ? 'translateY(0)' : `translateY(-${distance}px)`
      case 'left':
      return isVisible ? 'translateX(0)' : `translateX(-${distance}px)`
      case 'right':
        return isVisible ? 'translateX(0)' : `translateX(${distance}px)`
      default:
        return 'none';
    }
  };

  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
      style={{
        transition: `opacity ${duration} ease-in-out, transform ${duration} ease-in-out`,
        opacity: isVisible ? 1 : 0,
        transform: getTransform(),
      }}
    >
      {children}
    </div>
  )
}
export default FadeInWhenVisible