import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import FadeInWhenVisible from './fadeInWhenVisible'
import CustomLink from './CustomLink'
import { GatsbyImage } from 'gatsby-plugin-image'

class cta extends React.Component {
  render() {
    const Heading = this.props.headingLevel && Number.isInteger(this.props.headingLevel) ? `h${this.props.headingLevel}` : 'h2'
    const hasImage = !!this.props.ctaimage
    const hasLink = !!this.props.ctalink
    const hasSecondLink = !!this.props.ctasecondlink

    return (
      <section className="bg-blue-green-gradient">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>

              <MDBCol lg={hasImage ? '7' : '12'} md="12">
                <Heading className="font-alt font-w-700 text-white title-xs-medium title-large">
                  {this.props.ctatitle}
                </Heading>
                <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta">
                  {this.props.ctadescription}
                </p>

                <div className="btn-toolbar">
                  {hasLink && (
                    <CustomLink
                      link={this.props.ctalink}
                      className="nav-link btn btn-cta-color btn-sm-block mt-4 noeffect"
                      aria-label={this.props.ctatitle}
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      {this.props.ctalinktext}
                    </CustomLink>
                  )}

                  {hasSecondLink && (
                    <CustomLink
                      link={this.props.ctasecondlink}
                      className="nav-link btn btn-cta-color btn-sm-block mt-4 noeffect"
                      aria-label={this.props.ctatitle}
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      {this.props.ctasecondlinktext}
                    </CustomLink>
                  )}
                </div>
              </MDBCol>

              {hasImage && (
                <MDBCol lg="5" md="12" className="text-center image-center mt-md-4 d-none d-lg-block">
                  <GatsbyImage
                    image={this.props.ctaimage}
                    alt={this.props.ctaalttext}
                    className="alt-bg-image rounded"
                  />
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </FadeInWhenVisible>
      </section>
    )
  }
}
export default cta
