import React from 'react'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Showdown from 'showdown'

function setHeading(value) {
  if (Number.isInteger(value)) {
    if (value > 0) {
      value = `h${value}` 
    } else {
      value = "p"
    }
  } else {
    value = "h4"
  }
  return value
}

class CardIcons extends React.Component {
  render() {
    const Heading = setHeading(this.props.headingLevel)
    const converter = new Showdown.Converter()    
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-30 d-flex" >
        <div className="card flex-fill outline card-hover">
          <div className="card-body">
            <div style={{ minWidth: '85px', maxWidth: '85px', }} >
              <GatsbyImage
                image={this.props.image}
                className="mr-1"
                alt={this.props.alt}
              />
            </div>

            <div>
              <Heading className={this.props.headingClass ? this.props.headingClass : "font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3"}>
                {this.props.title}
              </Heading>
              {this.props.subtitle && (
                <div className="font-w-400 text-medium pt-2"
                  dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.subtitle)}}
               />
              )}
              {this.props.description && (
                <div className="font-w-400 text-medium divlink pt-2"
                  dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.description)}}
               />
              )}
              {this.props.anchorlink && (
                <a href={this.props.anchorlink} className="text-small effect" >
                  {this.props.anchortext}
                </a>
              )}
            </div>
          </div>
        </div>
      </MDBCol>
    )
  }
}
export default CardIcons