import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import FadeInWhenVisible from '../../components/fadeInWhenVisible.js'
import Card from '../../components/card'
import CardIcons from '../../components/cardIcons.js'
import CarouselQuote from '../../components/carouselQuote'  
import Cta from '../../components/cta'

const Support =({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <FadeInWhenVisible>
          <MDBContainer>
            <h2 className="font-alt font-w-700 title-xs-medium title-large pb-4">
              {post.frontmatter.howhelp.title}
            </h2>
            <div className="font-w-400 text-medium">
              {post.frontmatter.howhelp.subtitle}
            </div>
            {post.frontmatter.howhelp.custservice && (
              <MDBRow className="pt-5">
                {post.frontmatter.howhelp.custservice.map((custservices, index) => (
                  <CardIcons
                    key={index}
                    collg="4"
                    colmd="6"
                    title={custservices.title}
                    image={custservices.image.childImageSharp.gatsbyImageData}
                    alt={custservices.alttext}
                  />
                ))}
              </MDBRow>
            )}
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />

      <section className="bg-gray-light">
        <FadeInWhenVisible>
          <MDBContainer>
            <h2 className="font-alt text-left font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {post.frontmatter.supportservices.title}
            </h2>
            <h3 className="font-alt text-left font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {post.frontmatter.supportservices.subtitle}
            </h3>
            {post.frontmatter.supportservices.info && (
              <MDBRow className="pt-5">
                {post.frontmatter.supportservices.info.map((info, index) => (
                  <Card
                    key={index}
                    collg="4"
                    colmd="6"
                    height="16rem"
                    title={info.title}
                    subtitle={info.description}
                    subclass="font-w-400 text-xs-medium text-medium-small mt-2"
                    description={info.linktext}
                    image={info.image.childImageSharp.gatsbyImageData}
                    alt={info.alttext}
                    placement={info.placement}
                    link={info.link}
                    descriptionClass="text-card-small"
                  />
                ))}
              </MDBRow>
            )}
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      {post.frontmatter.quote && 
        <CarouselQuote data={post.frontmatter.quote} />
      }

    </Layout>
  )
}
export default Support

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "support" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug        
        supportservices {
          title
          subtitle
          info {
            title
            description
            placement
            linktext
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        howhelp {
          title
          subtitle
          custservice {
            title
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 70, height: 70, quality: 90) 
              }
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        quote: quotes {
          strapline: text
        }
      }
      html
    }
  }
`